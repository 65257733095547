/* index.scss */

@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700;900&family=Noto+Sans+JP:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

/* 1. import only the necessary Bootstrap files */
@import "bootstrap/scss/functions"; 
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

/* 2. begin customization ---- */

$theme-colors: (
  dark: black,
  primary: #007bff,
  secondary: #6c757d,
);

$font-family-sans-serif: "Noto Sans JP", "Inter", sans-serif;
$font-family-serif: "Merriweather", "Roboto Slab", serif;

/* --- end customization ------ */

/* 3. import Bootstrap to set the changes! */
@import "bootstrap";

/* 4. add @mixin or @extend customizations here */

/* create new custom classes from existing classes */

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.roboto {
  font-family: "Roboto Slab", sans-serif;
}
.merriweather {
  font-family: "Merriweather", serif;
}
.inter {
  font-family: "Inter", sans-serif;
}